import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

declare global {
  interface Window {
    clicky_site_ids?: number[];
  }
}

const CLICKY_SITE_ID = 101476387;

// Initialize Clicky tracking
(function() {
  // Define site IDs array first
  window.clicky_site_ids = window.clicky_site_ids || [];
  window.clicky_site_ids.push(CLICKY_SITE_ID);

  // Add tracking script
  const script = document.createElement('script');
  script.async = true;
  script.src = '//static.getclicky.com/js';
  
  script.onerror = () => {
    console.error('Failed to load Clicky Analytics');
  };
  
  document.head.appendChild(script);
})();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
